
import { geocode } from '@/modules/google-maps-api'
import MapLocation from '@/classes/MapLocation'
import Coordinate from '@/interfaces/Coordinate'

export class GeolocationError extends Error {
  constructor (message?: string) {
    super(message)
    this.name = this.constructor.name
  }
}

export function getCurrentPosition (): Promise<Coordinate> {
  if (!navigator.geolocation) {
    throw new GeolocationError('Geolocation is not supported by this browser.')
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => resolve({
        lat: coords.latitude,
        lng: coords.longitude,
      }),
      () => reject(new GeolocationError()),
    )
  })
}

export async function getCurrentMapLocation (): Promise<MapLocation> {
  try {
    const coordinate = await getCurrentPosition()
    const result = await geocode(coordinate)

    return MapLocation.fromGeocoderResult(result, coordinate)
  } catch (e) {
    throw new GeolocationError()
  }
}
