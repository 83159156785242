















import { defineComponent } from '@nuxtjs/composition-api'
import useI18n from '@/composables/use-i18n'

export default defineComponent({
  setup () {
    const i18n = useI18n()
    const features = [
      {
        title: i18n.t('index.features.price.title'),
        content: i18n.t('index.features.price.content'),
        img: require('@/assets/img/index-price.svg'),
        imgAlt: i18n.t('SEO.index.img.savingMoney'),
      },
      {
        title: i18n.t('index.features.customerService.title'),
        content: i18n.t('index.features.customerService.content'),
        img: require('@/assets/img/index-customer-service.svg'),
        imgAlt: i18n.t('SEO.index.img.customerService'),
      },
      {
        title: i18n.t('index.features.review.title'),
        content: i18n.t('index.features.review.content'),
        img: require('@/assets/img/index-review.svg'),
        imgAlt: i18n.t('SEO.index.img.ratingAward'),
      },
    ]
    return {
      features,
    }
  },
})
