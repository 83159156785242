











import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import useI18n from '@/composables/use-i18n'
import usePreservedQuery from '@/composables/use-preserved-query'

type TabValue = 'car' | 'motor'

export default defineComponent({
  props: {
    value: {
      type: String as PropType<TabValue>,
      default: 'car',
    },
  },
  setup (props, { emit }) {
    const { generateUrl } = usePreservedQuery()
    const i18n = useI18n()
    type Tab = {
      name: string,
      value: TabValue,
      link?: string,
      icon?: string,
      gaEvent: string
    }
    const tabs = computed<Tab[]>(() => {
      return [
        {
          name: i18n.t('searchBar.tab.car') as string,
          value: 'car',
          icon: 'icon-car',
          gaEvent: 'td_car',
        },
        {
          name: i18n.t('searchBar.tab.motor') as string,
          value: 'motor',
          link: generateUrl('/motor'),
          icon: 'icon-motor',
          gaEvent: 'td_scooter',
        },
      ]
    })

    const set = (value: TabValue) => emit('input', value)

    const getBind = ({ value, link, gaEvent }: Tab) => {
      type Attrs = { [name: string]: unknown }
      const attrs: Attrs = {
        class: { active: value === props.value, [value]: true, [gaEvent]: true },
      }
      if (link) attrs.href = link
      else attrs.type = 'button'
      return attrs
    }

    const getOn = (tab: Tab) => (!tab.link)
      ? { click: () => set(tab.value) }
      : {}

    return {
      tabs,
      set,
      getBind,
      getOn,
    }
  },
})
