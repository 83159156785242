

































import StartEnd from '@/enums/StartEnd'
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, PropType, ref, unref, watch } from '@nuxtjs/composition-api'
import { Options as PopperOptions } from '@popperjs/core'
import { whenever } from '@vueuse/core'
import { Dayjs } from 'dayjs'
import { interpret, Interpreter, State } from 'xstate'

import DateHourRange from '@/classes/DateHourRange'
import DatePickerBody from '@/components/date-picker/DatePickerBody.vue'
import useBreakpoint from '@/composables/use-breakpoint'
import { DatePickerContext, DatePickerEvent, datePickerMachine, DatePickerSchema } from '@/modules/date-picker-machine'

export default defineComponent({
  props: {
    activators: {
      type: Array as PropType<Element[]>,
      default: () => [],
    },
    menuOptions: {
      type: Object as PropType<PopperOptions>,
      default: () => ({
        placement: 'bottom-start',
        strategy: 'absolute',
        modifiers: [{ name: 'offset', options: { offset: [-16, 20] } }],
      }),
    },
    disallowedDates: {
      type: [Array, Function, null] as PropType<Dayjs[] | ((date: Dayjs) => boolean) | null>, // 若 callback 回傳為 true, 代表 date 為 disallowed
      default: null,
    },
    isUs: {
      type: Boolean,
      default: true,
    },
  },
  setup (_props, { emit }) {
    const current = ref<State<DatePickerContext, DatePickerEvent, DatePickerSchema> | null>(null)
    let service: Interpreter<DatePickerContext, DatePickerSchema, DatePickerEvent>
    onMounted(() => {
      service = interpret(datePickerMachine)
        .onTransition((state) => { current.value = state })
        .start()
    })
    onBeforeUnmount(() => service.stop())

    const body = ref<InstanceType<typeof DatePickerBody> | null>(null)

    const show = async (type: StartEnd, range?: DateHourRange) => {
      const payload = { range }

      if (type === StartEnd.START) service.send('CLICK_START', payload)
      else if (type === StartEnd.END) service.send('CLICK_END', payload)

      // 翻頁至「取車日期」那頁
      await nextTick()
      const start = range?.start.date
      if (start) body.value?.goTo(start)
    }

    const select = (date: Dayjs) => {
      service.send('PICK', { date })
      const context = unref(current)?.context
      if (context) emit('select', context)
    }

    const save = () => {
      service.send('SAVE')
      const context = unref(current)?.context
      if (context) emit('success', context)
    }
    const hide = () => service.send('CLOSE')

    const saveAndHide = () => {
      if (!isValid.value) save()
      hide()
    }

    const reset = () => service.send('RESET')

    const editingTarget = computed(() => {
      const value = unref(current)?.value as string
      if (['pickingStart', 'editingStart'].includes(value)) return StartEnd.START
      if (['pickingEnd', 'editingEnd'].includes(value)) return StartEnd.END
    })

    watch(editingTarget, value => emit('editing-target-change', value))

    const isShow = computed({
      get: () => unref(current)?.value !== 'initial',
      set: (value) => {
        if (!value) hide()
      },
    })

    whenever(() => !isShow.value, () => emit('select', {}))

    const isValid = computed(
      () => !unref(current)?.nextEvents.includes('SAVE')
    )

    const { match } = useBreakpoint()
    const isDual = computed(() => !match('xl'))

    watch(body, (val) => {
      emit('update:datePickerBody', val)
    })

    return {
      current,
      body,
      show,
      select,
      save,
      hide,
      saveAndHide,
      reset,
      editingTarget,
      isShow,
      isValid,
      isDual,
    }
  },
})
