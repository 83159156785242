import useI18n from '@/composables/use-i18n'

export type SeatsValue = null | ',5' | '6,' | '8,'

export type SeatListItem = {
  name: string,
  value: SeatsValue,
  disabled?: boolean,
  min?: number,
  max?: number
}

export default function () {
  const i18n = useI18n()

  const list: Array<SeatListItem> = [
    {
      name: String(i18n.t('field.seats.placeholder')),
      value: null,
    },
    {
      name: String(i18n.t('field.seats.option.lessThan', [5])),
      value: ',5',
      max: 5,
    },
    {
      name: String(i18n.t('field.seats.option.greaterThan', [6])),
      value: '6,',
      min: 6,
      max: 8,
    },
    {
      name: String(i18n.t('field.seats.option.greaterThan', [8])),
      value: '8,',
      min: 8,
    },
  ]

  const getName = (value: SeatsValue): string | undefined => {
    return list.find(item => item.value === value)?.name
  }

  return {
    list,
    getName,
  }
}
