import { nextTick, Ref, ref } from '@nuxtjs/composition-api'
import { Dayjs } from 'dayjs'

import DateHour from '@/classes/DateHour'
import DateHourRange from '@/classes/DateHourRange'
import StartEnd from '@/enums/StartEnd'

export default function (dateHourRange: Ref<DateHourRange>) {
  let startHourRange: number[] = DateHour.hourRange
  let endHourRange: number[] = DateHour.hourRange

  const setRange = async ({ start, end }: { start: Dayjs, end: Dayjs }) => {
    const range = dateHourRange.value.clone()
    range.setStartEndDate({ start, end })
    dateHourRange.value = range
    await adjustHour()
    await adjustEnd()
  }

  const setStart = (_: DateHour) => {
    const range = dateHourRange.value.clone()
    range.setStart(_)
    dateHourRange.value = range

    adjustEnd()
  }

  const setEnd = (_: DateHour) => {
    const range = dateHourRange.value.clone()
    range.setEnd(_)
    dateHourRange.value = range
  }

  const selectingValue = ref(new DateHourRange())
  const selectingType = ref<StartEnd | undefined>()

  const setSelectingType = (type?: StartEnd) => {
    selectingType.value = type
  }

  const setSelectingValue = ({ start, end }: { start: Dayjs, end: Dayjs }) => {
    const range = dateHourRange.value.clone()
    range.setStartEndDate({ start, end })
    selectingValue.value = range
  }

  // 選中今天日期時調整至合法的小時數
  const adjustHour = async () => {
    await nextTick()
    const newRange = dateHourRange.value.clone()
    const { start, end } = newRange

    const min = DateHour.getMinDateHour()
    if (!min.date) return

    if (start.date?.isSame(min.date)) {
      start.setHour(Math.max(min.hour, start.hour))
    }
    if (end.date?.isSame(min.date)) {
      end.setHour(Math.max(min.hour, end.hour))
    }
    dateHourRange.value = newRange
  }

  const setStartHourRange = (value: typeof DateHour.hourRange) => {
    startHourRange = value
  }

  const setEndHourRange = (value: typeof DateHour.hourRange) => {
    endHourRange = value
  }

  // 起迄時間為同天的話, 結束時間相較開始時間往後推一小, 超過最晚可租借時間, 推到隔天一早
  const adjustEnd = async () => {
    await nextTick()
    const newRange = dateHourRange.value.clone()
    const { start, end } = newRange

    if (newRange.isDateSame && start.instance && end.instance) {
      const min = DateHour.getMinDateHour(start.instance)

      if (min.hour > endHourRange[1]) {
        end.setDate(end.instance.add(1, 'd'))
        end.setHour(endHourRange[0])
      } else {
        min.date && end.setDate(min.date)
        end.setHour(min.hour)
      }
      setSelectingValue({ start: start.instance, end: end.instance })
    }

    dateHourRange.value = newRange
  }

  return {
    dateHourRange,

    setRange,
    setStart,
    setEnd,

    selectingType,
    setSelectingType,

    selectingValue,
    setSelectingValue,

    setStartHourRange,
    setEndHourRange,

    startHourRange,
    endHourRange,
  }
}
