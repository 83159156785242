

































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    location: {
      type: Object,
      required: true,
    },
    disableLink: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    return {}
  },
})
