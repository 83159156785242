import { Machine, assign } from 'xstate'
import { generateAutocompleteSessionToken, fetchDetail, fetchPredictions, GoogleMapsError } from '@/modules/google-maps-api'
import { getCurrentMapLocation } from '@/modules/geolocation'
import MapLocation from '@/classes/MapLocation'

export interface LocationPickerContext {
  keyword: string
  token: google.maps.places.AutocompleteSessionToken | null
  predictions: google.maps.places.AutocompletePrediction[] | null
  detail: PlaceDetail | null
  currentMapLocation: MapLocation | null
  error: Error | null
}

export interface LocationPickerSchema {
  states: {
    initial: {}
    shortcut: {}
    positioning: {}
    querying: {}
    prediction: {}
    fetchingDetail: {}
    saved: {}
    closed: {}
  }
}

type AutocompletePrediction = google.maps.places.AutocompletePrediction
type AutocompletePredictions = AutocompletePrediction[]
type PlaceResult = google.maps.places.PlaceResult

export type PlaceDetail = { place: PlaceResult, mainText: string }
export type PromiseData = AutocompletePredictions | PlaceDetail | MapLocation | Error

export type LocationPickerEvent =
  | { type: 'CLICK_FIELD', keyword: string, prediction: AutocompletePrediction, data: PromiseData }
  | { type: 'POSITION', keyword: string, prediction: AutocompletePrediction, data: PromiseData }
  | { type: 'SELECT', keyword: string, prediction: AutocompletePrediction, data: PromiseData }
  | { type: 'INPUT', keyword: string, prediction: AutocompletePrediction, data: PromiseData }
  | { type: 'SET_KEYWORD', keyword: string, prediction: AutocompletePrediction, data: PromiseData }
  | { type: 'GET_DETAIL', keyword: string, prediction: AutocompletePrediction, data: PromiseData }
  | { type: 'CLOSE', keyword: string, prediction: AutocompletePrediction, data: PromiseData }

// aliases
type Context = LocationPickerContext
type Schema = LocationPickerSchema
type Event = LocationPickerEvent

export const locationMachine = Machine<Context, Schema, Event>(
  {
    id: 'locationPicker',
    initial: 'initial',
    context: {
      keyword: '',
      token: null,
      predictions: null,
      detail: null,
      currentMapLocation: null,
      error: null,
    },
    states: {
      // 初始（關閉）
      initial: {
        entry: ['clearKeyword'],
        on: {
          CLICK_FIELD: 'shortcut',
          CLOSE: 'closed',
          SET_KEYWORD: {
            target: 'shortcut',
            actions: 'setKeyword',
          },
        },
      },
      // 「熱門地點」頁面
      shortcut: {
        entry: 'clearPredictions',
        exit: 'clearError',
        on: {
          SELECT: 'saved',
          POSITION: 'positioning',
          INPUT: {
            target: 'querying',
            cond: 'keywordChanged',
            actions: 'setKeyword',
          },
          CLOSE: 'closed',
        },
      },
      // 取得 GPS 中
      positioning: {
        entry: 'clearError',
        on: {
          SELECT: 'saved',
          INPUT: {
            target: 'querying',
            cond: 'keywordChanged',
            actions: 'setKeyword',
          },
          SET_KEYWORD: {
            target: 'shortcut',
            actions: 'setKeyword',
          },
          CLOSE: 'closed',
        },
        invoke: {
          src: getCurrentMapLocation,
          onDone: {
            target: 'saved',
            actions: 'setCurrentMapLocation',
          },
          onError: {
            target: 'shortcut',
            actions: 'setError',
          },
        },
      },
      // 查詢中
      querying: {
        entry: 'setToken',
        exit: 'clearError',
        always: {
          target: 'shortcut',
          cond: 'keywordEmpty',
          actions: ['setKeyword'],
        },
        on: {
          GET_DETAIL: 'fetchingDetail',
          INPUT: {
            target: 'querying',
            cond: 'keywordNotEmpty',
            actions: 'setKeyword',
          },
          CLOSE: 'closed',
        },
        invoke: {
          src: async ({ keyword, token }) => {
            if (!token) throw new GoogleMapsError('AutocompleteSessionToken not found!')
            return await fetchPredictions(keyword, token)
          },
          onDone: {
            target: 'prediction',
            actions: 'setPredictions',
          },
          onError: {
            target: 'querying',
            actions: 'setError',
          },
        },
      },
      // 預測結果列表
      prediction: {
        exit: 'clearError',
        on: {
          GET_DETAIL: 'fetchingDetail',
          INPUT: {
            target: 'querying',
            actions: 'setKeyword',
          },
          CLOSE: 'closed',
        },
      },
      // 正在取得詳細結果
      fetchingDetail: {
        invoke: {
          src: async ({ token }, { prediction }) => {
            if (!token) throw new Error('AutocompleteSessionToken not found!')
            return await fetchDetail(prediction, token)
          },
          onDone: {
            target: 'saved',
            actions: ['setDetail', 'clearToken'],
          },
          onError: {
            target: 'prediction',
            actions: ['setError'],
          },
        },
        on: {
          CLOSE: 'closed',
        },
      },
      saved: {
        exit: 'clearResult',
        on: {
          CLOSE: 'closed',
        },
      },
      closed: {
        always: 'initial',
      },
    },
  },
  {
    actions: {
      setKeyword: assign<Context, Event>({
        keyword: (_, { keyword }) => keyword,
      }),
      clearKeyword: assign<Context, Event>({
        keyword: () => '',
      }),

      setToken: assign<Context, Event>({
        token: ({ token }) => token ?? generateAutocompleteSessionToken(),
      }),
      clearToken: assign<Context, Event>({
        token: () => null,
      }),

      setPredictions: assign<Context, Event>({
        predictions: (_, { data }) => <AutocompletePredictions>data,
      }),
      clearPredictions: assign<Context, Event>({
        predictions: () => null,
      }),

      setDetail: assign<Context, Event>({
        detail: (_, { data }) => <PlaceDetail>data,
      }),
      setCurrentMapLocation: assign<Context, Event>({
        currentMapLocation: (_, { data }) => <MapLocation>data,
      }),

      clearResult: assign<Context, Event>({
        currentMapLocation: null,
        detail: null,
      }),

      setError: assign<Context, Event>({
        error: (_, { data }) => <Error>data,
      }),
      clearError: assign<Context, Event>({
        error: null,
      }),
    },
    guards: {
      keywordEmpty: ({ keyword }) => !keyword.length,
      keywordNotEmpty: ({ keyword }) => Boolean(keyword.length),
      keywordChanged: ({ keyword: newKeyword }, { keyword }) => newKeyword !== keyword,
    },
  }
)
