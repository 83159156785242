


























import { defineComponent, nextTick, PropType, ref } from '@nuxtjs/composition-api'
import VueScrollTo from 'vue-scrollto'

type Prediction = google.maps.places.AutocompletePrediction
type PredictionSubstring = google.maps.places.PredictionSubstring

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<Prediction[]>,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const select = (item: Prediction) => emit('get-detail', item)

    /** 分割文字以上色的匹配關鍵詞 */
    const getMatchedString = (
      text: string,
      substrings: PredictionSubstring[]
    ) => {
      const list: Array<{ string: string, matched?: boolean }> =
        [{ string: '' }]
      const chars = [...text]
      chars.forEach((char, index) => {
        if (substrings.some(({ offset }) => index === offset)) {
          list.push({ string: '', matched: true }) // 匹配的詞組
        } else if (substrings.some(({ offset, length }) => index === length + offset)) {
          list.push({ string: '' })
        }
        list[list.length - 1].string += char
      })

      return list
    }

    /** 取得分割後的地點名 */
    const getMainText = (item: Prediction) => getMatchedString(
      item.structured_formatting.main_text,
      item.structured_formatting.main_text_matched_substrings
    )

    /** 取得分割後的地點描述（即地址） */
    const getDescription = (item: Prediction) => getMatchedString(
      item.description,
      item.matched_substrings
    )

    /** 目前滑鼠或鍵盤的光標位置 */
    const cursor = ref(-1)

    /** 移動光標 */
    const moveCursor = async (x: 1 | -1) => {
      const { length } = props.list
      cursor.value = (cursor.value + x + length) % length

      await nextTick()
      // 滾動至該項目處以免其溢出而被蓋住
      if (container.value) {
        VueScrollTo.scrollTo(itemEls.value[cursor.value], {
          container: container.value,
          duration: 0,
        })
      }
    }

    /** 取消光標 */
    const clearCursor = () => { cursor.value = -1 }

    /** 選取目前光標指向的項目 */
    const selectCursor = () => {
      const item = props.list[cursor.value]
      if (item) select(item)
    }

    const container = ref<HTMLElement>()
    const itemEls = ref<HTMLElement[]>([])

    return {
      select,
      getMainText,
      getDescription,
      getMatchedString,

      cursor,
      moveCursor,
      clearCursor,
      selectCursor,

      container,
      itemEls,
    }
  },
})
