
import { ssrRef, useAsync, useContext } from '@nuxtjs/composition-api'
import ggoApi from '~/modules/ggo-api'
import ServiceLocations from '~/classes/ServiceLocation'

export default function () {
  const serviceLocations = ssrRef<ServiceLocations[]>([])
  const fetchAsyncData = async () => {
    const { error } = useContext()
    const { data, success } = await ggoApi.getServiceLocations()
    if (!success || !data) {
      error({ statusCode: 404 })
    } else {
      serviceLocations.value = []
      for (const serviceLocation of data) {
        const location = new ServiceLocations(serviceLocation)
        serviceLocations.value.push(location)
      }
    }
  }
  useAsync(fetchAsyncData)

  return {
    serviceLocations,
  }
}
