























import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'

import MapLocation from '@/classes/MapLocation'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
    returnOrNot: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    selectedValue: {
      type: String as PropType<string | null>,
      default: null,
    },
    selectedReturnValue: {
      type: String as PropType<string | null>,
      default: null,
    },
    checkReturnLocation: {
      type: [MapLocation, null] as PropType<MapLocation | null>,
      default: null,
    },
    errorMessage: {
      type: String as PropType<string | null>,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const input = ref<HTMLElement>()

    const focus = () => input.value?.focus()
    const blur = () => input.value?.blur()

    const proxyValue = computed({
      get: () => props.selectedValue ?? props.value,
      set: value => emit('input', value),
    })

    const proxyReturnValue = computed({
      get: () => props.selectedReturnValue ?? props.value,
      set: value => emit('input', value),
    })

    // 判斷要綁定 v-model 的 function 是 return or rent
    const locationType = props.returnOrNot ? proxyReturnValue : proxyValue

    const onFocus = () => emit('focus')

    const reference = ref<HTMLElement>()

    const up = (e: KeyboardEvent) => {
      if (e.isComposing) return
      emit('up', e)
    }
    const down = (e: KeyboardEvent) => {
      if (e.isComposing) return
      emit('down', e)
    }
    const esc = (e: KeyboardEvent) => {
      if (e.isComposing) return
      emit('esc', e)
    }
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.isComposing) return
      if (e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        emit('enter', e)
      }
      emit('startTyping')
    }

    const inputWrapperClass = computed(() => ({
      'rounded-full': props.rounded,
      'rounded-lg': !props.rounded,
    }))

    return {
      input,
      focus,
      blur,
      proxyValue,
      proxyReturnValue,
      locationType,
      onFocus,

      reference,

      up,
      down,
      esc,
      onKeyPress,

      inputWrapperClass,
    }
  },
})
