




























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { Context } from '@nuxt/types'

import useI18n from '@/composables/use-i18n'
import MapLocation from '@/classes/MapLocation'

const collectionIds = [
  'compact',
  'sportsCar',
  'welcab',
  'multiSeater',
  'volvo',
  'tesla',
  'rv',
] as const

type Collection = {
  name?: string
  img: string
  link: string
  query: Context['query']
  alt: string
}

type CollectionMode = 'link' | 'query'

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<(typeof collectionIds[number])[]>,
      default: () => [...collectionIds],
    },
    mode: {
      type: String as PropType<CollectionMode>,
      default: 'link' as CollectionMode,
    },
    location: {
      type: MapLocation as PropType<MapLocation | null>,
      default: null,
    },
  },
  setup (props) {
    const i18n = useI18n()

    const collections: { [key in typeof collectionIds[number]]: Collection } = {
      compact: {
        name: String(i18n.t('car.collection.compact')),
        img: 'https://cdn.gogoout.com/web/collection/compact.jpg',
        link: '/collection/compact-car-rental',
        query: { carType: '1' },
        alt: i18n.t('SEO.index.img.compact').toString(),
      },
      sportsCar: {
        name: String(i18n.t('car.collection.sport')),
        img: 'https://cdn.gogoout.com/web/collection/ferrari.jpg',
        link: '/collection/sport-car-rental',
        query: { carType: '5' },
        alt: i18n.t('SEO.index.img.sportsCar').toString(),
      },
      welcab: {
        name: String(i18n.t('car.collection.welcab')),
        img: 'https://cdn.gogoout.com/web/collection/welfare.jpg',
        link: '/collection/welcab-car-rental',
        query: { carType: '6' },
        alt: i18n.t('SEO.index.img.welcab').toString(),
      },
      multiSeater: {
        name: String(i18n.t('car.collection.multiSeater')),
        img: 'https://cdn.gogoout.com/web/collection/multiSeater.jpg',
        link: '/collection/multiSeater-car-rental',
        query: { seats: '8,' },
        alt: i18n.t('SEO.index.img.multiSeater').toString(),
      },
      volvo: {
        name: String(i18n.t('car.collection.volvo')),
        img: 'https://cdn.gogoout.com/web/collection/volvo.jpg',
        link: '/collection/volvo-car-rental',
        query: { brand: '30' },
        alt: i18n.t('SEO.index.img.volvo').toString(),
      },
      tesla: {
        name: String(i18n.t('car.collection.tesla')),
        img: 'https://cdn.gogoout.com/web/collection/tesla.jpg',
        link: '/collection/tesla-car-rental',
        query: { brand: '40' },
        alt: i18n.t('SEO.index.img.tesla').toString(),
      },
      rv: {
        name: String(i18n.t('car.collection.rv')),
        img: 'https://cdn.gogoout.com/web/collection/rv.jpg',
        link: '/collection/rv-car-rental',
        query: { carType: '8' },
        alt: i18n.t('SEO.index.img.rv').toString(),
      },
    }

    const displayedCollections = computed(() =>
      props.list?.map(id =>
        collections[id]
          ? ({
              ...collections[id],
              imageProps: {
                class: 'img',
                src: collections[id].img,
                alt: collections[id].alt,
                loading: 'lazy',
                preset: 'Home Collection',
              },
            })
          : null)
        .filter(Boolean)
    )

    const getSearchTo = ({ query }: Collection) => {
      // 隱匿機敏資訊
      let locationQueryObject: Partial<MapLocation['queryObject']> & {
        rentLat?: string,
        rentLng?: string,
      } = {}
      if (props.location?.queryObject) {
        locationQueryObject = {
          ...props.location.queryObject,
          rentLat: props.location.queryObject.lat,
          rentLng: props.location.queryObject.lng,
        }
        delete locationQueryObject.lat
        delete locationQueryObject.lng
      }

      return {
        path: '/car/search',
        query: {
          ...locationQueryObject,
          ...query,
        },
      }
    }

    const length = computed(() => displayedCollections.value?.length)

    return {
      displayedCollections,
      getSearchTo,
      length,
    }
  },
})
