










































import LocationPickerPrediction from '@/components/location-picker/LocationPickerPrediction.vue'
import SearchBarFieldLocation from '@/components/search-bar/SearchBarFieldLocation.vue'
import { defineComponent, onMounted, PropType, ref, toRefs, useContext } from '@nuxtjs/composition-api'

import useBodyTouchStartEvent from '@/composables/use-body-touch-start-event'
import useProxyValue from '@/composables/use-proxy-value'

import Shortcut from '@/interfaces/Shortcut'
import ShortcutGroup from '@/interfaces/ShortcutGroup'

type AutocompletePrediction = google.maps.places.AutocompletePrediction

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
    predictions: {
      type: Array as PropType<unknown[] | null>,
      default: null,
    },
    positioning: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  setup (props, { emit }) {
    // 監聽觸控點擊事件，點擊時讓輸入框失焦（為了隱藏虛擬鍵盤）
    const field = ref<InstanceType<typeof SearchBarFieldLocation> | null>(null)
    useBodyTouchStartEvent(() => field.value?.blur())

    const { proxyValue } = useProxyValue(toRefs(props).value, emit)

    const position = () => {
      emit('position')
    }

    const select = (item: Shortcut) => emit('select', item)
    const getDetail = (prediction: AutocompletePrediction) => {
      emit('get-detail', prediction)
    }

    const predictionEl = ref<InstanceType<typeof LocationPickerPrediction> | null>(null)

    const moveCursor = (x: 1 | -1) => predictionEl.value?.moveCursor(x)
    const clearCursor = () => predictionEl.value?.clearCursor()
    const selectCursor = () => predictionEl.value?.selectCursor()

    const shortcutGroups = ref<ShortcutGroup[] | null>(null)
    const { locale } = useContext().app.i18n

    onMounted(async () => {
      try {
        const res = await fetch('https://cdn.gogoout.com/json/map-search-field-menu-list.json')
        const data = await res.json()

        if (data) {
          shortcutGroups.value = data[locale]
        }
      } catch {}
    })

    return {
      field,
      proxyValue,
      position,
      select,
      getDetail,
      shortcutGroups,
      predictionEl,

      moveCursor,
      clearCursor,
      selectCursor,
    }
  },
})
