/* eslint-disable camelcase */

export type ServiceLocationsArguments = {
    id?: number;
    code?: string;
    title_i18n?: string;
    img?: string;
    img_alt?: string;
    sort?: number;
    url_path?: string;
    cities?: City[];
}
type City = {

    id?: number;

    title_i18n?: string;

    sort?: number;

    img?: string;

    lat?: number;

    lng?: number;

    max_distance: number;
    /** 導到城市的suggestion page url */
    url_path?: string;
}

export default class ServiceLocations {
    id?: number;
    /** 國家縮寫 e.g. TW */
    code?: string;

    title_i18n?: string;
    /** 國家圖片 */
    img?: string;
    /** 國家圖片alt */
    img_alt?: string;

    sort?: number;

    cities?: City[] =[];
    /** 導到國家的suggestion page url */
    url_path?: string;

    constructor (serviceLocations: ServiceLocationsArguments) {
      this.id = serviceLocations.id
      this.code = serviceLocations.code
      this.title_i18n = serviceLocations.title_i18n
      this.img = serviceLocations.img
      this.img_alt = serviceLocations.img_alt
      this.sort = serviceLocations.sort
      this.cities = serviceLocations.cities
      this.url_path = serviceLocations.url_path
    }
}
