import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f4215a58&lang=pug&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBar: require('/app/components/search-bar/SearchBar.vue').default,IndexBanner: require('/app/components/index/IndexBanner.vue').default,IndexSectionCarousel: require('/app/components/index/IndexSectionCarousel.vue').default,IndexSectionCollections: require('/app/components/index/IndexSectionCollections.vue').default,IndexSectionLocations: require('/app/components/index/IndexSectionLocations.vue').default,IndexSectionReviews: require('/app/components/index/IndexSectionReviews.vue').default,IndexSectionFeatures: require('/app/components/index/IndexSectionFeatures.vue').default,IndexSectionPromotion: require('/app/components/index/IndexSectionPromotion.vue').default})
