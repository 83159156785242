import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Machine, assign } from 'xstate'
import DateHourRange from '@/classes/DateHourRange'

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export interface DatePickerContext {
  start: Dayjs | null
  end: Dayjs | null
}

export interface DatePickerSchema {
  states: {
    initial: {}
    pickingStart: {}
    pickingEnd: {}
    editingStart: {}
    editingEnd: {}
    saved: {}
    closed: {}
  }
}

export type DatePickerEvent =
  | { type: 'CLICK_START', range: DateHourRange, date: Dayjs }
  | { type: 'CLICK_END', range: DateHourRange, date: Dayjs }
  | { type: 'PICK', range: DateHourRange, date: Dayjs }
  | { type: 'CLOSE', range: DateHourRange, date: Dayjs }
  | { type: 'SAVE', range: DateHourRange, date: Dayjs }
  | { type: 'RESET', range: DateHourRange, date: Dayjs }

// aliases
type Context = DatePickerContext
type Schema = DatePickerSchema
type Event = DatePickerEvent

export const datePickerMachine = Machine<Context, Schema, Event>(
  {
    id: 'datePicker',
    initial: 'initial',
    context: {
      start: null,
      end: null,
    },
    states: {
      initial: {
        on: {
          CLICK_START: [
            {
              target: 'editingStart',
              actions: ['setRange'],
              cond: 'startPicked',
            },
            {
              target: 'pickingStart',
              actions: ['setRange'],
            },
          ],
          CLICK_END: [
            {
              target: 'editingEnd',
              actions: ['setRange'],
              cond: 'endPicked',
            },
            {
              target: 'pickingStart',
            },
          ],
          RESET: {
            target: 'pickingStart',
            actions: 'clear',
          },
        },
      },
      pickingStart: {
        on: {
          PICK: {
            target: 'pickingEnd',
            actions: ['setStart'],
          },
          CLOSE: 'closed',
          RESET: {
            target: 'pickingStart',
            actions: 'clear',
          },
        },
      },
      pickingEnd: {
        on: {
          PICK: [
            {
              target: 'editingEnd',
              cond: 'dateSameOrAfterStart',
              actions: ['setEnd'],
            },
            {
              target: 'pickingEnd',
              actions: ['setStart'],
            },
          ],
          CLICK_START: 'pickingStart',
          CLOSE: 'closed',
          RESET: {
            target: 'pickingStart',
            actions: 'clear',
          },
        },
      },
      editingStart: {
        on: {
          PICK: [
            {
              target: 'editingEnd',
              cond: 'dateSameOrBeforeEnd',
              actions: ['setStart'],
            },
            {
              target: 'pickingEnd',
              cond: 'dateAfterEnd',
              actions: ['clearEnd', 'setStart'],
            },
          ],
          CLICK_END: 'editingEnd',
          SAVE: 'saved',
          CLOSE: 'closed',
          RESET: {
            target: 'pickingStart',
            actions: 'clear',
          },
        },
      },
      editingEnd: {
        on: {
          PICK: [
            {
              target: 'editingEnd',
              cond: 'dateBeforeStart',
              actions: ['setStart'],
            },
            {
              target: 'editingEnd',
              cond: 'dateSameOrAfterStart',
              actions: ['setEnd'],
            },
          ],
          CLICK_START: 'editingStart',
          SAVE: 'saved',
          CLOSE: 'closed',
          RESET: {
            target: 'pickingStart',
            actions: 'clear',
          },
        },
      },
      saved: {
        always: 'closed',
      },
      closed: {
        always: 'initial',
      },
    },
  },
  {
    actions: {
      clear: assign<Context, Event>({
        start: () => null,
        end: () => null,
      }),
      setRange: assign<Context, Event>({
        start: (_, { range }) => range.clone().start.date,
        end: (_, { range }) => range.clone().end.date,
      }),
      setStart: assign<Context, Event>({
        start: (_, { date }) => date.clone(),
      }),
      setEnd: assign<Context, Event>({
        end: (_, { date }) => date.clone(),
      }),
      clearEnd: assign<Context, Event>({
        end: () => null,
      }),
    },
    guards: {
      startPicked: (_context, { range }) => Boolean(range?.start.date),
      endPicked: (_context, { range }) => Boolean(range?.end.date),
      dateBefore: ({ start }, { date }) => Boolean(start && date.isBefore(start)),
      // 選擇的日期 ≤ 還車日期
      dateSameOrBeforeEnd: ({ end }, { date }) => Boolean(end && date.isSameOrBefore(end)),
      // 選擇的日期 > 還車日期
      dateAfterEnd: ({ end }, { date }) => Boolean(end && date.isAfter(end)),
      // 選擇的日期 < 取車日期
      dateBeforeStart: ({ start }, { date }) => Boolean(start && date.isBefore(start)),
      // 選擇的日期 ≥ 取車日期
      dateSameOrAfterStart: ({ start }, { date }) => Boolean(start && date.isSameOrAfter(start)),
    },
  }
)
