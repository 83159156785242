










import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    disableLink: {
      type: Boolean,
      default: false,
    },
  },
})
