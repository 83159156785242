import { Context } from '@nuxt/types'
import { ComputedRef, computed, ref, unref, useContext, useRouter, watch } from '@nuxtjs/composition-api'
import { MaybeRef } from '@vueuse/core'
import { Location } from 'vue-router/types'

import DateHourRange from '@/classes/DateHourRange'
import MapLocation from '@/classes/MapLocation'

import * as storage from '@/modules/location-picker-storage'
import { parseSearchUrlQuery } from '@/modules/route-query'

import dayjs, { UnitType } from 'dayjs'

/** 搜索相關數據 */
export interface SearchQueryObject {
  country?: string;
  location?: string;
  lat?: string;
  lng?: string;
  maxDistance?: number | null;

  returnLat?: number | null;
  returnLng?: number | null;
  returnLocation?: string | null;

  startDate: string | null;
  endDate: string | null;

  startHour: string | null;
  endHour: string | null;

  seats?: string | null;
}

const COUNTRY_PATH_MAP: { [key: string]: string } = {
  TW: '/car/search',
  CA: '/car/searchHertz',
  GU: '/car/searchHertz',
  US: '/car/searchHertz',
}
const getCountryPath = (country?: string) => {
  if (!country) return COUNTRY_PATH_MAP.TW
  return COUNTRY_PATH_MAP[country] ?? COUNTRY_PATH_MAP.TW
}

type SearchBarType = 'car' | 'motor'
type UseSearchBarOption = {
  pushQuery?: {
    /** 像store頁也會需要這個hook, 所以可以透過 pushSearchPage 去決定在當前 path push query 還是 push 到 search 頁 */
    pushSearchPage?: boolean
    pushCountry?: boolean
  }
}

const defaultUseSearchBarOption: UseSearchBarOption = {
  pushQuery: {
    pushSearchPage: true,
    pushCountry: true,
  },
}

type SetValueFromQueryOption = {
  fallbackDateHourRange?: boolean
}

/**
 * use-search-bar 的參數選項
 *
 * @description
 * 選項可以讓 use-search-bar 在其他頁面可以使用
 * pushQuery 裡可以控制在跳轉時的選項，pushCountry 為跳轉時要不要帶 country
 */
export default function (option: UseSearchBarOption = defaultUseSearchBarOption) {
  const location = ref<MapLocation | null>(null)
  const locationReturn = ref<MapLocation | null>(null)
  const dateHourRange = ref(new DateHourRange())
  const seats = ref<string | null>(null)

  /** 包含目前表單的地點、日期區間和座位數的 URL 參數 */
  const queryObject: ComputedRef<SearchQueryObject> = computed(() => {
    // 判斷還車地點是否與取車地點相符
    if (location.value?.country === 'US') {
      // 美國租車且取還車地點相符
      if (location.value?.name === locationReturn.value?.name) {
        return {
          ...location.value?.queryObject,
          returnLat: null,
          returnLng: null,
          returnLocation: null,
          ...dateHourRange.value.queryObject,
        }
      } else {
        return {
          ...location.value?.queryObject,
          // 若有還車地點則 querystring 帶入
          returnLat: Number(locationReturn.value?.coordinate?.lat),
          returnLng: Number(locationReturn.value?.coordinate?.lng),
          returnLocation: locationReturn.value?.name,
          ...dateHourRange.value.queryObject,
        }
      }
    } else {
      return {
        ...location.value?.queryObject,
        ...dateHourRange.value.queryObject,
        seats: seats.value,
      }
    }
  })

  const setValueFromQuery = (query: MaybeRef<Context['query']>, option?: SetValueFromQueryOption) => {
    const q = parseSearchUrlQuery(unref(query))

    if (q.mapLocation) location.value = q.mapLocation
    if (q.mapReturnLocation) locationReturn.value = q.mapReturnLocation
    if (q.mapLocation?.country === 'US' && !q.mapReturnLocation) {
      locationReturn.value = q.mapLocation
    }
    if (q.dateHourRange) {
      dateHourRange.value = q.dateHourRange

      if (option && option.fallbackDateHourRange) {
        handleFallbackDateHourRange(q.dateHourRange)
      }
    }
    if (q.seats) seats.value = String(q.seats)
  }
  /** 從 localStorage 設定地點 */
  const setLocationFromStorage = () => {
    const value = storage.get()
    if (value) {
      location.value = value
    }
  }

  const setReturnLocationFromStorage = () => {
    const value = storage.getReturnLocation()
    if (value) {
      locationReturn.value = value
    }
  }

  /** 將地點儲存至 localStorage */
  const saveLocation = () => {
    if (locationReturn.value) storage.setReturnLocation(locationReturn.value)
    if (location.value) storage.set(location.value)
  }

  const type = ref<SearchBarType>('car')

  const { query, params, app: { localePath } } = useContext()
  const router = useRouter()
  const path = ref<string>('')
  const setPath = (value: string) => { path.value = value }
  watch(query, (val) => {
    /** 進到 search 頁後，filter 欄位 push query 時，需從 route 判斷目前的取車地點位於何方 */
    if (option.pushQuery?.pushCountry && val.country) {
      setPath(getCountryPath(val.country.toString()))
    }
  })

  const pushQuery = (value: Location['query']) => {
    // 重新搜尋移除正在點選的店家
    delete query.value.activeStore

    /** 首頁選擇租車地點時判斷去到 Hertz or Taiwan */
    if (value?.country) {
      if (option.pushQuery?.pushSearchPage) { setPath(getCountryPath(value.country.toString())) }
      // todo: hard code -> 避免將機敏資料（經緯度）暴露在 url 上，將 lat lng key 替換
      // https://developers.facebook.com/community/threads/492687301790173/
      const newQuery = {
        ...query.value, // 目前的 URL Query
        ...value, // 將複寫的 URL Query
      }

      if (newQuery.lat) {
        newQuery.rentLat = newQuery.lat
        delete newQuery.lat
      }

      if (newQuery.lng) {
        newQuery.rentLng = newQuery.lng
        delete newQuery.lng
      }

      router.push(localePath({
        path: path.value,
        query: newQuery,
        params: params.value,
      }))
    } else {
      if (option.pushQuery?.pushSearchPage) { setPath(getCountryPath(query?.value?.country?.toString())) }
      // todo: hard code -> 避免將機敏資料（經緯度）暴露在 url 上，將 lat lng key 替換
      const newQuery = {
        ...query.value, // 目前的 URL Query
        ...value, // 將複寫的 URL Query
      }

      if (newQuery.lat) {
        newQuery.rentLat = newQuery.lat
        delete newQuery.lat
      }

      if (newQuery.lng) {
        newQuery.rentLng = newQuery.lng
        delete newQuery.lng
      }

      router.push(localePath({
        path: path.value,
        query: newQuery,
        params: params.value,
      }))
    }
  }

  const handleFallbackDateHourRange = (range: DateHourRange) => {
    if (range.isStartExpired()) {
      setDefaultStartDateHour()
    }
    if (range.isEndExpired()) {
      setDefaultEndDateHour()
    }
  }

  const setDefaultStartDateHour = () => {
    const start = dayjs().add(1, 'd').set('h', 8)

    dateHourRange.value.start.setDate(start)
    dateHourRange.value.start.setHour(start.hour())
  }

  const setDefaultEndDateHour = () => {
    const end = dayjs().add(2, 'd').set('h', 8)

    dateHourRange.value.end.setDate(end)
    dateHourRange.value.end.setHour(end.hour())
  }

  const isStartDateMoreThan = (value: number, unit: UnitType, base = dayjs()) => {
    if (!dateHourRange.value.start.date) return false
    return dateHourRange.value.start.date.diff(base, unit) >= value
  }

  return {
    location,
    locationReturn,
    dateHourRange,
    seats,
    queryObject,
    setValueFromQuery,
    setLocationFromStorage,
    setReturnLocationFromStorage,
    saveLocation,
    type,
    pushQuery,
    setPath,
    isStartDateMoreThan,
  }
}
