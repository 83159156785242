










import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'

import useI18n from '@/composables/use-i18n'

import Review from '@/classes/Review'

export default defineComponent({
  props: {
    reviews: {
      type: Array as PropType<Array<Review> | null>,
      default: null,
    },
  },
  setup (props) {
    const i18n = useI18n()

    const defaultReviews: Review[] = [
      new Review({
        location: String(i18n.t('about.comment.comment.1.name')),
        content: String(i18n.t('about.comment.comment.1.content')),
        score: 5,
      }),
      new Review({
        location: String(i18n.t('about.comment.comment.2.name')),
        content: String(i18n.t('about.comment.comment.2.content')),
        score: 5,
      }),
      new Review({
        location: String(i18n.t('about.comment.comment.3.name')),
        content: String(i18n.t('about.comment.comment.3.content')),
        score: 5,
      }),
      new Review({
        location: String(i18n.t('about.comment.comment.4.name')),
        content: String(i18n.t('about.comment.comment.4.content')),
        score: 5,
      }),
    ]

    const list = computed(() => {
      // 若是沒餵 props.reviews 進來, 餵預設
      if (!props.reviews) return defaultReviews
      else return props.reviews
    })

    return {
      list,
    }
  },
})
