import { render, staticRenderFns } from "./IndexSectionFeatures.vue?vue&type=template&id=64c20140&scoped=true&lang=pug&"
import script from "./IndexSectionFeatures.vue?vue&type=script&lang=ts&"
export * from "./IndexSectionFeatures.vue?vue&type=script&lang=ts&"
import style0 from "./IndexSectionFeatures.vue?vue&type=style&index=0&id=64c20140&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c20140",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexHeading: require('/app/components/index/IndexHeading.vue').default,IndexWrapperSection: require('/app/components/index/IndexWrapperSection.vue').default})
