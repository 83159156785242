import { isNumber, isObject, isString } from 'typechecker'

export type ReviewArgument = {
  name?: string,
  location?: string,
  date?: string,
  img?: string,
  content?: string,
  score?: number,
  brand?: string
}

/** 評論 */
export default class Review {
  /** 客戶名稱 */
  name?: string
  /** 客戶的地點 */
  location?: string
  /** 評論的日期時間 */
  date?: string
  /** 客戶的大頭照 */
  img?: string
  /** 評論內容 */
  content?: string
  /** 評論分數 */
  score?: number
  /** 車型 */
  brand?: string

  constructor (review: ReviewArgument) {
    if (!isObject(review)) throw new TypeError('`review` must be an object!')

    if (isString(review.name)) this.name = review.name
    if (isString(review.location)) this.location = review.location
    if (isString(review.date)) this.date = review.date
    if (isString(review.img)) this.img = review.img
    if (isString(review.content)) this.content = review.content
    if (isNumber(review.score)) this.score = review.score
    if (isString(review.brand)) this.brand = review.brand
  }
}
