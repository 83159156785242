








































































































































import StartEnd from '@/enums/StartEnd'
import { computed, defineComponent, watch, PropType, ref, unref, useContext } from '@nuxtjs/composition-api'

import DatePicker from '@/components/date-picker/DatePicker.vue'
import LocationPicker from '@/components/location-picker/LocationPicker.vue'
import SearchBarFieldDateHour from '@/components/search-bar/SearchBarFieldDateHour.vue'
import SearchBarFieldDate from '@/components/search-bar/SearchBarFieldDate.vue'
import SearchBarFieldLocation from '@/components/search-bar/SearchBarFieldLocation.vue'

import DateHourRange from '@/classes/DateHourRange'
import MapLocation from '@/classes/MapLocation'

import useBreakpoint from '@/composables/use-breakpoint'
import useDateHourRange from '@/composables/use-date-hour-range'
import useI18n from '@/composables/use-i18n'
import { SeatsValue } from '@/composables/use-seats'
import Country from '@/enums/Country'
import { whenever } from '@vueuse/shared'

import * as storage from '@/modules/location-picker-storage'
import ggoLogger from '~/modules/ggo-logger'
import { LogName, LogToastMsg } from '~/interfaces/Logger'

export default defineComponent({
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hideTabs: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    location: {
      type: MapLocation as PropType<MapLocation | null>,
      default: null,
    },
    locationReturn: {
      type: MapLocation as PropType<MapLocation | null>,
      default: null,
    },
    dateHourRange: {
      type: DateHourRange as PropType<DateHourRange>,
      default: (): DateHourRange => new DateHourRange(),
    },
    seats: {
      type: String as PropType<SeatsValue>,
      default: null,
    },
    type: {
      type: String,
      default: 'car',
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()
    const { query } = useContext()

    const proxyType = computed({
      get: () => props.type,
      set: value => emit('update:type', value),
    })

    // Location
    const proxyLocation = computed({
      get: () => props.location,
      set: value => emit('update:location', value),
    })

    const proxyReturnLocation = computed({
      get: () => props.locationReturn,
      set: (value) => {
        if (value?.country === Country.TAIWAN) {
          emit('update:location', value)
        } else {
          emit('update:locationReturn', value)
        }
      },
    })

    const keyword = ref<string>('')
    const locationField = ref<InstanceType<typeof SearchBarFieldLocation> | null>(null)
    const locationPicker = ref<InstanceType<typeof LocationPicker> | null>(null)
    const locationFieldReturn = ref<InstanceType<typeof SearchBarFieldLocation> | null>(null)
    const locationPickerReturn = ref<InstanceType<typeof LocationPicker> | null>(null)
    const isLocationPickerShow = ref<boolean>(false)
    const isReturnLocationPickerShow = ref<boolean>(false)

    const search = () => {
      // eslint-disable-next-line no-undef
      fbq('track', 'Search')
    }

    const showLocationPicker = (e?: 'rent' | 'return') => {
      if (e === 'rent') {
        const locationName = proxyLocation.value?.name

        if (locationName) locationPicker.value?.setKeyword(locationName)
        if (locationPicker.value?.isDialog) {
            locationField.value?.blur()
        } else {
            locationField.value?.focus()
        }
          locationPicker.value?.show()
      } else {
        const locationReturnName = proxyReturnLocation.value?.name

        if (locationReturnName) locationPickerReturn.value?.setKeyword(locationReturnName)
        if (locationPickerReturn.value?.isDialog) {
          locationFieldReturn.value?.blur()
        } else {
          locationFieldReturn.value?.focus()
        }
        locationPickerReturn.value?.show()
      }
    }

    const selectedLocationName = computed(() => {
      return isLocationPickerShow.value ? null : proxyLocation.value?.name
    })

    const selectedReturnLocationName = computed(() => {
      return isReturnLocationPickerShow.value ? null : proxyReturnLocation.value?.name
    })

    const locationShortname = ref<string | undefined>(
        query.value.country as string ? query.value.country as string : storage.get()?.country ? storage.get()?.country : Country.TAIWAN
    )

    const checkLocationShortname = (value: MapLocation | null) => {
      locationShortname.value = value?.country
    }

    const setLocation = (value: MapLocation | null) => {
      checkLocationShortname(value)
      if (locationShortname.value === 'US' || locationShortname.value === 'CA' || locationShortname.value === 'GU') {
        proxyReturnLocation.value = value
      }
      proxyLocation.value = value
        locationField.value?.blur()
    }

    const setReturnLocation = (value: MapLocation | null) => {
      checkLocationShortname(value)
      proxyReturnLocation.value = value
        locationFieldReturn.value?.blur()
    }

    const clearLocationErrorMessage = () => locationPicker.value?.clearErrorMessage()
    const clearLocationReturnErrorMessage = () => locationPickerReturn.value?.clearErrorMessage()

    // DateHour
    const proxyDateHourRange = computed({
      get: () => props.dateHourRange,
      set: value => emit('update:dateHourRange', value),
    })
    const datePicker = ref<InstanceType<typeof DatePicker> | null>(null)
    const startField = ref<InstanceType<typeof SearchBarFieldDateHour> | null>(null)
    const endField = ref<InstanceType<typeof SearchBarFieldDateHour> | null>(null)
    const startDateField = ref<InstanceType<typeof SearchBarFieldDate> | null>(null)
    const endDateField = ref<InstanceType<typeof SearchBarFieldDate> | null>(null)

    const activators = computed(() => [startDateField.value?.$el, endDateField.value?.$el])

    const showDatePicker = (type: StartEnd = StartEnd.END) => {
        datePicker.value?.show(type, unref(proxyDateHourRange))
    }
    watch(() => props.location, (newValue) => {
      if (newValue) {
        setLocation(newValue) // 由於剛進入此頁面時，locationShortName沒有吃到新的值，會導致甲租乙還出現問題
      }
    }, { immediate: true })

    const {
      setRange,
      setStart,
      setEnd,
      selectingType,
      setSelectingType,
      selectingValue,
      setSelectingValue,
      setStartHourRange,
      setEndHourRange,
    } = useDateHourRange(proxyDateHourRange)

    // Seats
    const proxySeats = computed({
      get: () => props.seats,
      set: value => emit('update:seats', value),
    })
    const fieldBind = computed(() => ({
      hideLabel: props.hideLabel,
    }))

    const wrapperClass = computed(() => ({
      border: props.outlined,
      'shadow-y': !props.outlined,
    }))

    const formClass = computed(() => ({
      'shadow-y': !props.outlined,
    }))

    // Submit
    const isLocationValid = computed(() => {
      return Boolean(props.location)
    })

    const isRangeValid = computed(() => props.dateHourRange.isValid)
    const isRangeDateEmpty = computed(() => props.dateHourRange.isDateEmpty)
    const isRangeSame = computed(() => props.dateHourRange.isDateHourSame)
    const isRangeStartAfterEnd = computed(() => props.dateHourRange.isStartAfterEnd)

    const hourErrorMessage = ref<string | null>(null)

    const submit = () => {
      if (!isLocationValid.value) {
        ggoLogger.info(LogName.TOAST, {
          type: 'danger',
          message: LogToastMsg.SEARCH_LOCATION_INVALID,
        })

        showLocationPicker()
      } else if (isRangeDateEmpty.value) {
        ggoLogger.info(LogName.TOAST, {
          type: 'danger',
          message: LogToastMsg.SEARCH_DATE_EMPTY,
        })

        showDatePicker()
      } else if (isRangeSame.value || isRangeStartAfterEnd.value) {
        ggoLogger.info(LogName.TOAST, {
          type: 'danger',
          message: LogToastMsg.SEARCH_DATE_INVALID,
        })

        hourErrorMessage.value = String(i18n.t('message.sameDateHourRange'))
      } else {
        ggoLogger.info(LogName.TOAST, {
          type: 'success',
          message: LogToastMsg.SEARCH_DATE_VALID,
        })

        emit('submit')
      }
    }

    // 當起訖時間不一樣就清除錯誤訊息
    whenever(
      () => !(isRangeSame.value || isRangeStartAfterEnd.value),
      () => { hourErrorMessage.value = null }
    )

    // 鍵盤事件

    // 記錄先前的鍵盤事件
    // 若單純用打字輸入地點，按下 enter 後會觸發 moveCursor 事件再觸發 enter
    let lastKey = false

    const onUp = (e: 'rent' | 'return') => {
      lastKey = true
      e === 'rent' ? locationPicker.value?.moveCursor(-1) : locationPickerReturn.value?.moveCursor(-1)
    }

    const onDown = (e: 'rent' | 'return') => {
      lastKey = true
      e === 'rent' ? locationPicker.value?.moveCursor(1) : locationPickerReturn.value?.moveCursor(1)
    }

    const onEsc = ({ target }: { target: HTMLInputElement }) => {
        target?.blur()
        locationPicker.value?.hide()
        locationPickerReturn.value?.hide()
    }

    const onEnter = (e: 'rent' | 'return') => {
      if (e === 'rent') {
        if (lastKey === false) {
          locationPicker.value?.moveCursor(1)
          locationPicker.value?.selectCursor()
        } else {
          locationPicker.value?.selectCursor()
        }
      } else if (lastKey === false) {
          locationPickerReturn.value?.moveCursor(1)
          locationPickerReturn.value?.selectCursor()
      } else {
          locationPickerReturn.value?.selectCursor()
      }
      lastKey = false
    }
    const startTyping = (e: 'rent' | 'return') => {
      if (e === 'rent') {
          locationPicker.value?.show()
      } else {
          locationPickerReturn.value?.show()
      }
    }
    const { match } = useBreakpoint()
    const isMobile = computed(() => !match('xl'))

    // 動態控制顯示欄位
    // todo: 待聚焦問題，先把這兩個判斷隱藏
    /** 動態控制顯示欄位: 還車地點 */
    const showLocationPickerReturnFields = computed(() => locationShortname.value === Country.UNITED_STATES || locationShortname.value === Country.CANADA || locationShortname.value === Country.GUAM)
    /** 動態控制顯示欄位: 座位 */
    const showSeatFields = computed(() => locationShortname.value === Country.TAIWAN || locationShortname.value === Country.JAPAN || locationShortname.value === Country.KOREA || locationShortname.value === Country.THAILAND)

    return {
      StartEnd,

      search,

      proxyType,
      keyword,
      selectedLocationName,
      selectedReturnLocationName,
      locationField,
      locationPicker,
      locationFieldReturn,
      locationPickerReturn,
      isLocationPickerShow,
      isReturnLocationPickerShow,
      showLocationPicker,
      locationShortname,
      checkLocationShortname,
      setLocation,
      setReturnLocation,
      proxyReturnLocation,
      clearLocationErrorMessage,
      clearLocationReturnErrorMessage,

      proxyDateHourRange,
      setRange,
      setStart,
      setEnd,
      datePicker,
      showDatePicker,

      proxySeats,
      fieldBind,
      startField,
      endField,
      activators,
      selectingType,
      selectingValue,
      setSelectingValue,
      setSelectingType,
      wrapperClass,
      formClass,

      submit,
      startTyping,
      isLocationValid,
      isRangeValid,

      lastKey,
      onUp,
      onDown,
      onEsc,
      onEnter,
      isMobile,

      hourErrorMessage,

      showLocationPickerReturnFields,
      showSeatFields,

      startDateField,
      endDateField,

      setStartHourRange,
      setEndHourRange,
    }
  },
})
