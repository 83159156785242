import { onMounted } from '@nuxtjs/composition-api'
import { useEventListener } from '@vueuse/core'

export default function (callback: Function) {
  const handler = ({ target }: TouchEvent) => {
    if (target instanceof HTMLElement && target?.tagName !== 'INPUT') {
      callback()
    }
  }

  onMounted(() => useEventListener(window, 'touchstart', handler))
}
