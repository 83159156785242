




























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import Review from '@/classes/Review'

export default defineComponent({
  props: {
    review: {
      type: Review as PropType<Review | null>,
      default: null,
    },
    height: {
      type: Number as PropType<number | null>,
      default: null,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const toFixed = (n: number) => n?.toFixed(1)

    const cardStyle = computed(() =>
      props.height
        ? { height: `${props.height}px` }
        : null
    )

    return {
      toFixed,
      cardStyle,
    }
  },
})
