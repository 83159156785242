































import { defineComponent, computed, ref, unref, onBeforeUnmount, watch, onMounted } from '@nuxtjs/composition-api'
import { Options as PopperOptions } from '@popperjs/core'
import { interpret, Interpreter, State } from 'xstate'

import { locationMachine, LocationPickerContext, LocationPickerSchema, LocationPickerEvent } from '@/modules/location-picker-machine'

import useBreakpoint from '@/composables/use-breakpoint'
import useI18n from '@/composables/use-i18n'

import MapLocation from '@/classes/MapLocation'
import Shortcut from '@/interfaces/Shortcut'
import { GeolocationError } from '@/modules/geolocation'

import LocationPickerBody from '@/components/location-picker/LocationPickerBody.vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const current = ref<State<LocationPickerContext, LocationPickerEvent, LocationPickerSchema> | null>(null)
    let service: Interpreter<LocationPickerContext, LocationPickerSchema, LocationPickerEvent>
    onMounted(() => {
      service = interpret(locationMachine)
        .onTransition((state) => { current.value = state })
        .start()
    })
    onBeforeUnmount(() => service.stop())

    const proxyValue = computed({
      get: () => current.value?.context.keyword,
      set: keyword => service.send('INPUT', { keyword }),
    })
    watch(() => props.value, (keyword) => { proxyValue.value = keyword })
    watch(proxyValue, keyword => emit('input', keyword))

    watch(current, (current) => {
      if (current?.value === 'saved') {
        const { context } = current
        if (context.detail) {
          const { place, mainText } = context.detail
          emit('success', MapLocation.fromPlaceResult(place, mainText))
        } else if (context.currentMapLocation) {
          emit('success', context.currentMapLocation)
        }
        hide()
      }
    })

    const error = computed(() => current.value?.context.error)
    const errorMessage = ref<string | null>(null)
    const clearErrorMessage = () => { errorMessage.value = null }

    const i18n = useI18n()
    watch(() => current.value?.context.error, (error) => {
      if (error) {
        if (error instanceof GeolocationError) {
          errorMessage.value = String(i18n.t('message.failedToPosition'))
        }
      } else {
        clearErrorMessage()
      }
    })

    const show = () => service.send('CLICK_FIELD')

    const position = () => service.send('POSITION')

    const select = (shortcut: Shortcut) => {
      service.send('SELECT')
      emit('success', MapLocation.fromShortcut(shortcut))
    }
    const getDetail = (prediction: google.maps.places.AutocompletePrediction) => {
      service.send('GET_DETAIL', { prediction })
    }
    const hide = () => service.send('CLOSE')

    const isShow = computed({
      get: () => unref(current)?.value !== 'initial',
      set: (value) => {
        if (!value) hide()
      },
    })

    watch(isShow, value => value ? emit('show') : emit('hide'))

    const predictions = computed(() => current.value?.context.predictions)
    watch(predictions, (list) => {
      errorMessage.value = (list && !list?.length)
        ? String(i18n.t('message.failedToSearchLocation'))
        : null
    })

    const isPositioning = computed(() => current.value?.value === 'positioning')
    const setKeyword = (keyword: string) => {
      service.send('SET_KEYWORD', { keyword })
    }

    const menuOptions: PopperOptions = {
      placement: 'bottom-start',
      strategy: 'absolute',
      modifiers: [{ name: 'offset', options: { offset: [-20, 20] } }],
    }

    const { match } = useBreakpoint()
    const isDialog = computed(() => !match('xl'))

    const body = ref<InstanceType<typeof LocationPickerBody> | null>(null)
    const moveCursor = (x: 1 | -1) => body.value?.moveCursor(x)
    const clearCursor = () => body.value?.clearCursor()
    const selectCursor = () => body.value?.selectCursor()

    return {
      current,
      error,
      errorMessage,
      clearErrorMessage,
      show,
      hide,
      position,
      select,
      getDetail,
      isShow,
      predictions,
      isPositioning,
      setKeyword,
      menuOptions,
      proxyValue,
      isDialog,

      body,
      moveCursor,
      clearCursor,
      selectCursor,
    }
  },
})
