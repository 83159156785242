

























import { computed, defineComponent, PropType, ref, toRefs } from '@nuxtjs/composition-api'
import GSelectMenu from '@/components/GSelectMenu.vue'
import useCustomSelect from '@/composables/use-custom-select'
import useSeats, { SeatsValue } from '@/composables/use-seats'
import useBreakpoint from '@/composables/use-breakpoint'

export default defineComponent({
  props: {
    value: {
      type: String as PropType<SeatsValue>,
      default: null,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, context) {
    const { match } = useBreakpoint()
    const isMobile = !match('xl')

    const showCustomizedSelect = computed(() => !isMobile)

    const selectMenu = ref<InstanceType<typeof GSelectMenu> | null>(null)

    const { value } = toRefs(props)
    const { list } = useSeats()

    const showMenu = () => {
      selectMenu.value?.show()
    }

    return {
      ...useCustomSelect(value, list, context),
      selectMenu,
      showMenu,

      showCustomizedSelect,
    }
  },
})
