


































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { useEventListener } from '@vueuse/core'
import VueScrollTo from 'vue-scrollto'

import Shortcut from '@/interfaces/Shortcut'

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<{ name: string, list: Shortcut[] }[]>,
      default: () => [],
    },
  },
  setup (_, { emit }) {
    const select = (item: Shortcut) => {
      emit('select', item)
    }
    const active = ref<number>(0)

    const container = ref<HTMLElement>() // 滾動範圍
    const sections = ref<HTMLElement[]>([]) // 區塊列表

    // 滾動監控
    const onScroll = () => {
      const current = container.value?.scrollTop ?? 0
      active.value = sections.value.findIndex(el => el.offsetTop >= current)
    }
    useEventListener(container, 'scroll', onScroll)

    // 滾動至區塊
    const clickTab = (index: number) => {
      if (!container.value) return
      VueScrollTo.scrollTo(sections.value[index], {
        offset: -10,
        container: container.value,
      })
    }

    return {
      select,
      active,
      container,
      sections,
      clickTab,
    }
  },
})
