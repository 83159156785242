
































import { defineComponent } from '@nuxtjs/composition-api'
import useServiceLocations from '@/composables/use-service-locations'

import useBreakpoint from '@/composables/use-breakpoint'
import GCarousel from '@/components/GCarousel.vue'
// @ts-ignore
import Slide from 'vue-carousel/src/Slide.vue'

export default defineComponent({
  components: {
    GCarousel,
    Slide,
  },
  props: {
    disableLink: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const { match } = useBreakpoint()
    const isMobile = !match('lg')
    const { serviceLocations: locations } = useServiceLocations()

    return {
      locations,

      isMobile,
    }
  },
})
