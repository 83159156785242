import { render, staticRenderFns } from "./Locations.vue?vue&type=template&id=50e18e43&scoped=true&lang=pug&"
import script from "./Locations.vue?vue&type=script&lang=ts&"
export * from "./Locations.vue?vue&type=script&lang=ts&"
import style0 from "./Locations.vue?vue&type=style&index=0&id=50e18e43&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e18e43",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LocationCard: require('/app/components/location/LocationCard.vue').default,GCarousel: require('/app/components/GCarousel.vue').default})
